<template>
  <div align="center">
    <v-img :src="require('../../assets/main-logo1.png')" width="150"></v-img>
    <p style="width: 80%">
      Join our newsletter to stay up to date on work experience and the world of
      Medical Internships.
    </p>

    <div class="mt-5">
      <div align="center">
        <div class="ml-2" style="color: white; width: 60%" align="start">
          {{ $t("email") }}
        </div>

        <v-text-field
          class="ml-2 custom-email-field"
          v-model="email"
          density="compact"
          :placeholder="$t('email')"
          prepend-inner-icon="mdi-email-outline"
          style="width: 60%"
          bg-color="white"
          :rules="emailRules"
        ></v-text-field>
      </div>

      <div class="ml-2 mt-1" align="center">
        <v-btn
          color="#3fb984"
          :loading="onSubscribing"
          @click="onSubscribing = true"
          style="width: 60%"
          >Subscribe</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue"
import { useI18n } from "vue-i18n"

export default defineComponent({
  name: "SubscribingComponent",

  data() {
    const { t } = useI18n()
    const email = ref<string | null>(null)
    const onSubscribing = ref(false)
    const emailRules = [
      (v: string) => !!v || t("email-required"),
      (v: string) => /.+@.+\..+/.test(v) || t("email-authentic"),
    ]
    return { email, emailRules, onSubscribing }
  },
})
</script>

<style>
.custom-email-field .v-messages {
  color: white !important;
}
</style>
