<template>
  <div style="background-color: #e1f5fd">
    <v-divider class="mb-10"></v-divider>

    <h1 style="margin-top: 50px; font-size: 48px" align="center">
      {{ $t("the-answer") }}: MEDCIRCLE
    </h1>
    <div align="start" justify="space-evenly">
      <v-slide-group :show-arrows="true">
        <v-slide-group-item v-for="(card, index) in cards" :key="index">
          <v-card
            class="mx-auto ml-10 mt-10 mb-10"
            :class="smallWindow ? 'mr-5' : 'mr-10'"
            :width="smallWindow ? 250 : 500"
            elevation="10"
            style="border-radius: 5%"
          >
            <v-img height="200px" :src="getImage(card.photo)" cover></v-img>

            <v-card-title> {{ card.title }} </v-card-title>

            <!-- <v-card-subtitle> 1,000 miles of wonder </v-card-subtitle> -->

            <v-chip
              class="ml-3 mt-2"
              v-for="city in card.cities"
              :key="city"
              prepend-icon="mdi-map-marker"
              >{{ city }}</v-chip
            >
            <v-card-actions>
              <v-btn color="#3fb984" text="Explore"></v-btn>

              <v-spacer></v-spacer>

              <v-btn
                :icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                @click="show = !show"
              ></v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>

                <v-card-text>
                  {{ card.explanation }}
                </v-card-text>
                <v-card-text v-if="card.subExplanation">
                  <p style="font-weight: 500">
                    {{ card.subExplanation }}
                  </p>
                </v-card-text>
                <div v-if="card.bulletPoints">
                  <v-card-text v-for="point in card.bulletPoints" :key="point">
                    <v-icon color="#3fb984" icon="mdi-check"></v-icon>
                    {{ point }}
                  </v-card-text>
                </div>
                <div v-if="card.logo">
                  <v-img height="150px" :src="getImage(card.logo)"></v-img>
                </div>
              </div>
            </v-expand-transition>
          </v-card>
        </v-slide-group-item>
      </v-slide-group>

      <v-divider style="margin-top: 100px"></v-divider>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"

export interface AdvantageCard {
  explanation: string
  subExplanation?: string
  title: string
  photo: string
  bulletPoints?: string[]
  cities?: string[]
  logo?: string
}

export default defineComponent({
  name: "AdvantageDisadvantageComponent",

  data() {
    const { t } = useI18n()
    const cards = ref<AdvantageCard[]>([])
    cards.value = [
      {
        explanation:
          "Die Famulatur (von lat. famulus = “Gehilfe”) ist ein Praktikum,  in dem Medizinstudenten praktische Erfahrungen im Krankenhaus oder einer Praxis erlangen sollen.",
        title: "Famulaturen",
        photo: "doc-line.jpg",
        subExplanation: "MEDCIRCLE bietet ihnen Famulaturenplätze",
        bulletPoints: [
          "In verschiedene Fachrichtungen",
          "In private Kliniken und auch in Krankenhäusern",
        ],
        cities: ["München", "Ulm", "Freising"],
      },
      {
        explanation:
          "Das Praktische Jahr – auch als PJ bekannt - ist der letzte Abschnitt des Medizinstudiums vor dem 3. Abschnitt der Ärztlichen Prüfung, wobei insgesamt zwölf Monate Praktische Tätigkeit in einer Universitätsklinik oder einer von der Universität anerkannten Klinik abgeleistet werden müssen. Beginn des PJs ist zweimal pro Jahr – im Mai und im November.",
        title: "Praktisches Jahr(PJ)",
        subExplanation: "Aktuell bieten wir keine Plätze fur PJs",
        photo: "single-doc.jpg",
        logo: "work-in-progress.png",
      },

      {
        explanation:
          "Ärzt­li­che Weiter­bil­dung bezweckt das Erler­nen beson­de­rer ärzt­li­cher Fähig­kei­ten und Fertig­kei­ten nach Ertei­lung der Berech­ti­gung zur Ausübung des ärzt­li­chen Berufs. Dauer und Inhalt der Weiter­bil­dung rich­ten sich nach den Bestim­mun­gen der Weiter­bil­dungs­ord­nung, bei Weiter­bil­dungs­gän­gen nach den Vorga­ben der außer Kraft getre­te­nen Weiter­bil­dungs­ord­nung für die Ärzte Bayerns vom 24. April 2004 (WBO 2004) zusätz­lich auch nach den dazu­ge­hö­ri­gen Richt­li­nien.",
        title: "Weiterbildung",
        subExplanation:
          "Aktuell bieten wir keine Plätze fur Weitenbildungsmöglichkeiten",
        photo: "doc-research.jpg",
        logo: "work-in-progress.png",
      },
    ]

    const show = ref(false)

    function getImage(photo: string | undefined) {
      return require(`../../../assets/${photo}`)
    }

    const smallWindow = ref(window.innerWidth < 1000)

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })

    return {
      t,
      cards,
      show,
      getImage,
      smallWindow,
    }
  },
})
</script>

<style>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1 0 20%; /* Adjust the width as needed */
}
</style>
