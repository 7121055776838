<template>
  <header-component></header-component>
  <!-- <p class="text text-center">MEDCIRCLE</p> -->

  <FirstComponent></FirstComponent>

  <SecondComponent></SecondComponent>

  <AdvantageDisadvantage></AdvantageDisadvantage>

  <CounterComponent></CounterComponent>

  <v-divider style="margin-top: 100px"></v-divider>

  <top-doctors-component></top-doctors-component>
  <v-divider></v-divider>
  <!-- <top-hospitals></top-hospitals> -->

  <supported-component></supported-component>
  <footer-component></footer-component>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import HeaderComponent from "../Header/HeaderComponent.vue"

import FooterComponent from "../Footer/FooterComponent.vue"
import SupportedComponent from "../Landing/SupportedPartners/SupportedPartners.vue"
import TopDoctorsComponent from "../Top-Doctors/Top-Doctors.vue"
import { useI18n } from "vue-i18n"
import { useUserActions } from "@/stores/user-actions"
import { useMeta } from "vue-meta"
import CounterComponent from "./Counter/CounterComponent.vue"
import AdvantageDisadvantage from "./Advantage-Disadvantage/Advantage-Disadvantage.vue"
import TopHospitals from "../TopHospitals/TopHospitals.vue"
import SecondComponent from "./SecondComponent.vue"
import FirstComponent from "./FirstComponent.vue"
export default defineComponent({
  components: {
    HeaderComponent,
    FirstComponent,
    FooterComponent,
    AdvantageDisadvantage,
    TopDoctorsComponent,
    CounterComponent,
    SupportedComponent,
    SecondComponent,
  },
  name: "LandingComponent",

  setup() {
    onMounted(() => {
      localStorage.removeItem("searchParams")
      localStorage.removeItem("docId")
      localStorage.removeItem("hospitalId")
      isTokenValid()
    })

    const smallWindow = ref(false)
    const canSearch = ref(false)

    const isTokenValid = async (): Promise<void> => {
      const userAction = useUserActions()
      const response = await userAction.isTokenValid()
      updateWindowSize()
      if (!response) {
        localStorage.removeItem("accessToken")
      }
    }
    localStorage.removeItem("searchParams")

    const { t } = useI18n()
    useMeta(
      computed(() => ({
        title: t("landing-title"),
      }))
    )
    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1200
    }

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })

    return {
      smallWindow,
      canSearch,
    }
  },
})
</script>

<style>
.sub-text {
  font-size: 20px;
  font-weight: 300;
}
.image {
  margin-top: 0px;
}

.content {
  overflow-x: hidden;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
