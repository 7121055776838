<template>
  <DoctorHeader></DoctorHeader>
  <request-status :response="responseStatus" :isLoading="loading" />
  <v-card>
    <v-layout>
      <v-navigation-drawer
        :expand-on-hover="smallWindow"
        :rail="smallWindow"
        :permanent="!smallWindow"
        mobile-breakpoint="xs"
        width="230"
        height="100vh"
      >
        <v-list>
          <v-list-item
            :prepend-avatar="
              docAction.doctorProfileLink !== ''
                ? docAction.doctorProfileLink
                : require('../../../assets/blank.png')
            "
            :title="name"
          >
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item
            prepend-icon="mdi-account"
            :title="$t('doctor.overview')"
            :class="{
              'v-list-item--active': selectedItem === 'Doctor Overview',
              'v-list-item--inactive': selectedItem !== 'Doctor Overview',
            }"
            @click="navigateTo('Doctor Overview')"
          >
          </v-list-item>

          <!-- <v-list-item
            prepend-icon="mdi-inbox-arrow-down"
            title="Inbox"
            stacked
            @click="selectedItem = 'construct'"
          >
            <template v-slot:append>
              <v-badge color="error" content="!" inline></v-badge>
            </template>
          </v-list-item> -->
          <v-list-item
            prepend-icon="mdi-radio-tower"
            title="Broadcast"
            stacked
            :class="{
              'v-list-item--active': selectedItem === 'Broadcast',
              'v-list-item--inactive': selectedItem !== 'Broadcast',
            }"
            @click="navigateTo('Broadcast')"
          >
          </v-list-item>

          <v-list-item
            prepend-icon="mdi-account-group"
            :title="$t('your-circle')"
            stacked
            :class="{
              'v-list-item--active': selectedItem === 'Subscribers',
              'v-list-item--inactive': selectedItem !== 'Subscribers',
            }"
            @click="navigateTo('Subscribers')"
          >
          </v-list-item>
          <v-list-item
            prepend-icon="mdi-calendar"
            :title="$t('calendar')"
            stacked
            :class="{
              'v-list-item--active': selectedItem === 'Doctor Calendar',
              'v-list-item--inactive': selectedItem !== 'Doctor Calendar',
            }"
            @click="navigateTo('Doctor Calendar')"
          >
            <template v-slot:append>
              <v-badge color="blue" :content="$t('new')" inline></v-badge>
            </template>
          </v-list-item>

          <v-list-item
            v-if="invites.length > 0 && !isAccepted"
            prepend-icon="mdi-account-multiple-plus"
            :title="$t('doctor.invites')"
            :class="{
              'v-list-item--active': selectedItem === 'Doctor Invite',
              'v-list-item--inactive': selectedItem !== 'Doctor Invite',
            }"
            @click="showInviteDialog = true"
            ><template v-slot:append>
              <v-badge color="blue" :content="invites.length" inline></v-badge>
            </template>
          </v-list-item>
          <v-dialog max-width="500" v-model="showInviteDialog" persistent>
            <template v-slot:default="{ isActive }">
              <DoctorInviteDialog
                @close="isActive.value = false"
                :invites="invites"
              />
            </template>
          </v-dialog>
        </v-list>
      </v-navigation-drawer>

      <v-main style="min-height: 100vh">
        <router-view />
      </v-main>
    </v-layout>
  </v-card>
  <FooterComponent></FooterComponent>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue"

import { useI18n } from "vue-i18n"
import DoctorHeader from "../Headers/DoctorHeader.vue"
import { doctorActions } from "@/stores/doctor-actions"
import FooterComponent from "@/components/Footer/FooterComponent.vue"
import { useMeta } from "vue-meta"
import router from "@/router"
import { useRoute } from "vue-router"
import { Invite, RequestResponse } from "@/types"
import DoctorInviteDialog from "./DoctorInviteDialog.vue"
export default defineComponent({
  components: {
    DoctorHeader,
    FooterComponent,
    DoctorInviteDialog,
  },
  name: "DoctorDashboard",

  setup() {
    const { t } = useI18n()
    const selectedItem = ref()
    const docProfileLink = ref("")
    const name = ref("")
    const drawer = ref(true)
    const rail = ref(true)
    const applicationId = ref<string>()
    const smallWindow = ref(window.innerWidth < 600)
    const docAction = doctorActions()
    const route = useRoute()
    const responseStatus = ref<RequestResponse>()
    const invites = ref<Invite[]>([])
    const loading = ref(false)
    const isAccepted = ref(false)
    const showInviteDialog = ref(false)

    onMounted(async () => {
      router.push({ name: routeName.value ?? "Doctor Overview" })
      selectedItem.value = routeName.value ?? "Doctor Overview"

      docProfileLink.value = docAction.doctorProfileLink
      name.value = docAction.docName
      await getInvite()
    })

    const routeName = computed(() => {
      return route.name
    })

    const getInvite = async () => {
      loading.value = true
      const response = await docAction.getHospitalInvite()

      responseStatus.value = response

      if (response.success) {
        invites.value = response.result as Invite[]

        invites.value.forEach((invite: Invite) => {
          if (invite.status == "accept") {
            isAccepted.value = true
          }
        })
      }
      if (invites.value.length > 0 && !isAccepted.value) {
        showInviteDialog.value = true
      }

      loading.value = false
    }

    useMeta(
      computed(() => ({
        title: name.value ?? "Medcircle",
      }))
    )

    watch([routeName], () => {
      selectedItem.value = routeName.value ?? "Doctor Overview"
    })

    onMounted(() => {
      window.addEventListener("resize", updateWindowSize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateWindowSize)
    })

    const updateWindowSize = (): void => {
      smallWindow.value = window.innerWidth < 1000
    }

    return {
      selectedItem,
      docProfileLink,
      name,
      drawer,
      rail,
      smallWindow,
      applicationId,
      routeName,
      docAction,
      responseStatus,
      loading,
      invites,
      isAccepted,
      showInviteDialog,
    }
  },
  methods: {
    navigateTo(item: string) {
      this.selectedItem = item
      if (this.selectedItem == "Doctor Calendar") {
        this.$router.push({
          path: `/doctor-dashboard/calendar/doctor`,
        })
      } else {
        this.$router.push({ name: item })
      }
    },
  },
})
</script>

<style>
.text {
  font-size: 50px;
  font-weight: 500;
  margin-top: 130px;
}
.sub-text {
  font-size: 20px;
  font-weight: 300;
}

.content {
  overflow-x: hidden;
}
.footer {
  margin-top: 200px;
}
</style>
